.account-form-title {
  font-weight: 700;
  // font-size: 1.6rem;
  letter-spacing: 0;
  margin-top: 2.2rem;
  color: $dark-text;
}
.account-form-title-underline {
  width: 3rem;
  height: 0.6rem;
  margin-bottom: 1.8rem;
  background-color: $primary-color;
}
.account-form-button {
  display: flex;
  flex-direction: column;
  align-items: center;
}
