@import "../config/variables";

.report-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20rem;
  margin: 16px 32px;
  color: $dark-text;
  background-color: $dark-background;
  cursor: pointer;
  height: 26rem;
  padding: 2rem;
  border-radius: 1rem;
  .report-list-item-icon {
    width: 16rem;
    height: 16rem;
    object-fit: contain;
    transition: all 0.2s ease;
  }
  .report-list-item-download-icon {
    color: white;
    width: 4rem;
    height: 4rem;
  }
  .report-list-item-title {
    font-size: 1.4rem;
    font-weight: 600;
    color: white;
  }
  .report-list-item-description {
    font-size: 1.4rem;
    color: white;
  }
  .overlay {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: -16rem;
    height: 16rem;
    width: 17rem;
    opacity: 0;
    transition: 0.3s ease;
    background-color: $primary-color;
  }
  &:hover {
    .overlay {
      opacity: 0.8;
    }
  }
}
.report-list-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}
