@import "./config/variables";

.mobile-menu-wrapper .nav.nav-pills-mobile .nav-link {
  &::before {
    content: none;
  }
}

.mobile-menu-wrapper .nav-item.show .nav-link {
  color: $primary-color !important;
}

.home-page {
  .owl-carousel.owl-simple .owl-dots {
    z-index: 100;
    position: relative;
    margin-bottom: -1rem;
    margin-bottom: 3rem;
  }

  .banner-1:before {
    padding-top: 50.9rem;
  }

  .banner-2:before {
    padding-top: 50.9rem;
  }

  .banner-3:before,
  .banner-4:before {
    padding-top: 24.5rem;
  }
}

.welcome-msg {
  white-space: nowrap;
}

.cart-dropdown {
  .btn-primary {
    color: white;
    background-color: $primary-color;
    border-color: $primary-color;

    &:hover {
      color: $primary-color;
      border-color: $primary-color;
      background-color: white;
    }
  }

  .btn-outline-primary-2 {
    color: $primary-color;
    background-color: white;
    border-color: $primary-color;

    &:hover {
      color: white;
      background-color: $primary-color;
    }
  }
}

.intro-slider-container,
div.intro-slide {
  // height: 400px;
  // background-color: #789;
}

div.intro-slide {
  display: flex;
  align-items: center;
  background-size: cover;
  background-position: center center;

  .btn-primary {
    color: #fff;
    background-color: transparent;
    border-color: #fff;

    &:hover,
    &:focus {
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }

  &.slide1 {
    background-position: 72%;
    background-repeat: no-repeat;
    background-size: auto;

    img {
      left: 42%;
      width: 756px;
      max-width: 756px;
      top: -15%;
      z-index: -1;
    }
  }

  &.slide3 {
    background-position: 79% -32%;
    background-repeat: no-repeat;
    background-size: auto;
  }
}
.home-slider-container {
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  // background-color: red;
}
.home-slider-image {
  width: 100vw;
  flex: 1;
  min-width: calc(100vw - 40px);
  // max-width: 1200px;
  object-fit: cover;
  object-position: center top;
  height: 600px;
  margin-top: -140px;
}
.intro-content {
  position: static;
  left: auto;
  top: auto;
  transform: translateY(0);
  -ms-transform: translateY(0);
}

.intro-subtitle {
  font-size: 1.6rem;
  font-weight: 400;
  color: #fff;
  letter-spacing: -0.01em;
  white-space: pre-line;
}

.intro-title {
  font-weight: 600;
  color: #fff !important;
  letter-spacing: -0.025em;
  white-space: pre-line;

  sup {
    vertical-align: top;
    font-size: 1.3rem;
    top: 1em;
    margin-right: 0;
    letter-spacing: -0.01em;

    & + sup {
      margin-left: 0.8rem;
    }
  }
}

.intro-slider-container,
div.intro-slide {
  // height: 500px;
}

.brands-border .brand {
  height: 100px;
  border: 0.1rem solid #ebebeb;
  border-left-width: 0;
}

.brands-carousel-full {
  border-bottom: 0.1rem solid #ebebeb;

  &.brands-border {
    .brand {
      border-right-width: 0;
      border-top-width: 0;
      border-bottom-width: 0;
    }

    .owl-item:first-child .brand {
      border-left-width: 0;
    }
  }
}

.banner-group {
  margin-bottom: 3rem;

  @include mq("lg") {
    .col-lg-3 {
      -ms-flex: 0 0 26.05%;
      flex: 0 0 26.05%;
      max-width: 26.05%;
    }

    .col-lg-4 {
      -ms-flex: 0 0 32.77%;
      flex: 0 0 32.77%;
      max-width: 32.77%;
    }

    .col-lg-5 {
      -ms-flex: 0 0 41.18%;
      flex: 0 0 41.18%;
      max-width: 41.18%;
    }
  }
}

.home-page {
  .banner-content {
    &.banner-content-top {
      top: 2.6rem;
      transform: translateY(0);
      -ms-transform: translateY(0);
    }

    &.banner-content-bottom {
      bottom: 3rem;
    }

    .banner-large & {
      left: 3rem;
    }
  }

  .btn.banner-link {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    text-transform: capitalize;
  }

  .banner-subtitle {
    color: #666666;
    font-weight: 300;
    font-size: 1.4rem;
    letter-spacing: -0.01em;
    margin-bottom: 0.6rem;

    &.text-grey {
      color: #ebebeb;
    }
  }

  .banner-title {
    color: #333;
    font-weight: 600;
    font-size: 2.4rem;
    margin-top: 4rem;
    letter-spacing: -0.025em;
    margin-bottom: 1.4rem;
  }

  .banner-text {
    color: #666666;
    font-weight: 300;
    font-size: 2.4rem;
    line-height: 1.25;
    letter-spacing: -0.01em;
    margin-bottom: 1.3rem;

    // .banner-title + & {
    //   margin-top: -1.4rem;
    // }

    .banner-large & {
      color: #333;
    }
  }

  .nav.nav-pills {
    .nav-item.active,
    .nav-item.show {
      .nav-link,
      .nav-link.active {
        color: #333;
        border-bottom-color: #333;
      }
    }
  }

  .nav.nav-border-anim .nav-link:before {
    background-color: #333;
  }

  .nav-pills.nav-big {
    .nav-item + .nav-item {
      margin-left: 1.5rem;
    }

    .nav-link {
      padding-top: 0.8rem;
      padding-bottom: 0.8rem;
      color: #ccc;
      font-weight: 600;
      font-size: 2.4rem;
      letter-spacing: -0.025em;
      text-transform: capitalize;

      &:hover,
      &:focus,
      &.active {
        color: black;
      }
    }
  }

  /* Product 8 */
  .product.product-8 {
    .product-body {
      padding-top: 1.5rem;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .new-price {
      color: #ef837b;
    }

    .btn-product {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      background-color: $primary-color;

      span {
        font-size: 1.3rem;
      }

      &:before {
        color: #fff;
      }

      &:hover,
      &:focus {
        background-color: $secondary-color;
      }
    }
  }

  .products .label-top {
    display: none;
  }
}

.deal-container {
  .banner-subtitle {
    color: #666;
    margin-bottom: 0.9rem;
    font-weight: 400;
  }

  .banner-content.banner-content-top {
    top: 4rem;
  }

  .banner-text {
    font-weight: 600;
  }
}

.deal {
  display: block;
  padding: 2rem;
  background-color: #fff;
  margin-bottom: 2rem;
}

.deal-content {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  padding: 3.5rem 0 2rem;

  .btn {
    padding-top: 0.85rem;
    padding-bottom: 0.85rem;
    font-weight: 500;
    color: #333;
    background-color: transparent;
    border: 0.1rem solid #ebebeb;

    &:hover,
    &:focus {
      color: #fff;
      border-color: $primary-color;
      background-color: $primary-color;
    }
  }

  h4 {
    color: $primary-color;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.3;
    letter-spacing: -0.01em;
    margin-bottom: 0.5rem;
  }

  h2 {
    font-weight: 600;
    font-size: 4rem;
    line-height: 1.1;
    letter-spacing: -0.05em;
    margin-bottom: 3.6rem;
  }

  .product-price {
    font-weight: 300;
    font-size: 2rem;
    margin-bottom: 2.4rem;
  }

  .new-price {
    font-weight: 600;
  }
}

.deal-image {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;

  > a {
    display: block;
  }
}

.deal-countdown {
  margin-bottom: 4rem;
  margin-left: -1rem;
  max-width: 270px;

  .countdown-show3 .countdown-section {
    width: calc(33.33% - 20px);
  }

  .countdown-section {
    font-size: 1.2rem;
    line-height: 1;
    padding: 1.6rem 0;
    margin-left: 1rem;
    margin-right: 1rem;
    background-color: $primary-color;
    border-radius: 0.3rem;
    border: 0.1rem solid #e1e1e1;

    &:not(:last-child):after {
      color: #333333;
      content: ":";
      display: inline-block;
      font-weight: 400;
      font-size: 20px;
      line-height: 1;
      position: absolute;
      left: 100%;
      margin-left: 9px;
      margin-top: -1px;
      top: 50%;
      transform: translateY(-50%);
      -ms-transform: translateY(-50%);
    }
  }

  .countdown-period {
    display: block;
    font-size: 1.2rem;
    font-weight: 400;
    color: #fff;
    text-transform: lowercase;
    width: 100%;
    padding-left: 0.2rem;
    padding-right: 0.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .countdown-amount {
    display: inline-block;
    color: #fff;
    font-weight: 600;
    font-size: 3rem;
    letter-spacing: -0.05em;
    margin-bottom: 0.4rem;
  }
}

.btn-more {
  text-transform: capitalize;
}

.icon-box-side {
  .icon-box-title {
    font-weight: 600;
  }

  p {
    font-weight: 400;
    letter-spacing: -0.01em;
  }
}

.blog-posts {
  .entry-media {
    margin-bottom: 1.7rem;
  }

  .entry-meta {
    color: #cccccc;
    margin-bottom: 0.8rem;
  }

  .entry-title {
    font-weight: 500;
    font-size: 1.8rem;
    letter-spacing: -0.01em;
    margin-bottom: 0.5rem;
  }
}

.footer {
  .widget-about-info {
    a {
      color: $primary-color;
    }
  }

  .widget-title {
    font-weight: 600;
  }
}

.footer-newsletter {
  .input-group {
    flex-direction: column;
    align-items: center;
  }

  .form-control {
    width: 100%;
    margin-bottom: 1rem;
  }

  .btn-primary {
    background-color: #fff;
    color: #333;
    font-weight: 500;
    min-width: 150px;
    padding-left: 1rem;
    padding-right: 0;
    border: none;

    &:hover,
    &:focus {
      color: #fff;
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }
}

@include mq("sm") {
  .intro-slider-container,
  div.intro-slide {
    // height: 450px;
  }

  .footer-newsletter {
    .input-group {
      flex-direction: row;
      align-items: stretch;
    }

    .form-control {
      width: auto;
      margin-bottom: 0;
    }
  }
}

@include mq("md") {
  .intro-slider-container,
  div.intro-slide {
    // height: 560px;
  }

  .intro-title {
    sup {
      font-size: 1.6rem;
    }
  }

  .home-page .banner-content {
    &.banner-content-top {
      top: 3.6rem;
    }

    &.banner-content-bottom {
      bottom: 4rem;
    }

    .banner-large & {
      left: 5rem;
    }
  }

  .intro-title {
    margin-bottom: 2.5rem;
  }

  .deal {
    display: flex;
    flex-direction: row;
  }

  .deal-content {
    -ms-flex: 0 0 47.8%;
    flex: 0 0 47.8%;
    max-width: 47.8%;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .deal-image {
    -ms-flex: 0 0 52.2%;
    flex: 0 0 52.2%;
    max-width: 52.2%;
  }
}

@include mq("lg") {
  .intro-title {
    sup {
      font-size: 1.8rem;
    }
  }

  .home-page .banner-content {
    .banner-large & {
      left: 7rem;
    }
  }
}

@include mq("xl") {
  .intro-slider-container,
  div.intro-slide {
    // height: 600px;
  }

  .intro-content {
    .btn {
      min-width: 170px;
    }
  }

  .intro-title {
    font-size: 5rem;

    sup {
      font-size: 2rem;
    }

    span {
      font-weight: 300;
    }
  }

  .deal-content {
    padding: 3.5rem 3rem 2rem;

    .btn {
      padding-top: 1.15rem;
      padding-bottom: 1.15rem;
    }

    h4 {
      font-size: 2rem;
    }

    h2 {
      font-size: 4rem;
    }

    .product-price {
      font-size: 2.4rem;
    }
  }

  .deal-countdown {
    .countdown-section {
      padding-top: 2.1rem;
      padding-bottom: 2.1rem;
    }

    .countdown-amount {
      font-size: 3rem;
    }
  }
}

@include mq("lg", "max") {
  .header-2 .header-middle .container::after {
    content: none;
  }

  div.intro-slide.slide3 {
    background-position-x: 250px;
  }
}

@include mq("md", "max") {
  .home-page {
    .banner-1,
    .banner-2 {
      &:before {
        padding-top: 35rem;
      }
    }
  }
}

@include mq("sm", "max") {
  .product.product-11 .btn-product span {
    font-size: 1.2rem;
  }
}

.intro-button {
  color: #fff;
}
