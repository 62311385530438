@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat-Regular"),
    url("../fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Black Mango Black";
  src: local("Black Mango Black"),
    url("../fonts/Black Mango Black.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "GreatVibes-Regular";
  src: local("GreatVibes-Regular"),
    url("../fonts/GreatVibes-Regular.ttf") format("truetype");
  font-weight: normal;
}

// Base
html {
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-size-adjust: 100%;
}
//dots

$dot-simple-width: 8px !default;
$dot-simple-active-width: 18px !default;
$dot-simple-height: 8px !default;
$dot-simple-rounded: 30px !default;
$dot-simple-margin: 5px 6px !default;
$dot-simple-border: #d7d7d7 !default;
$dot-simple-background: transparent !default;
$dot-simple-border-active: #cccccc !default;
$dot-simple-background-active: $primary-color !default;
html,
body {
  height: 100%;
}

body {
  font: normal 300 #{$font-size}/ 1.86 $font-family;
  font-family: "Black Mango Black", "GreatVibes-Regular", "Montserrat-Regular",
    sans-serif;
  color: $body-text;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  -ms-overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-y: auto;
}

::-moz-selection {
  color: #fff;
  background-color: $primary-color;
}

::selection {
  color: #fff;
  background-color: $primary-color;
}

p {
  margin-bottom: 0;
  font-size: 1.4rem;
  font-weight: 300;
  letter-spacing: 0;
  color: #777;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0 0 1.5rem;
}

b,
strong {
  font-weight: 700;
}

em,
i {
  font-style: italic;
}

hr {
  border: none;
  border-top: 1px solid #ebebeb;
  margin: 3rem auto 2.5rem;
}

sub,
sup {
  position: relative;
  font-size: 70%;
  line-height: 0;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

button {
  -webkit-appearance: none;
  border: none;
  background-color: transparent;
}

.bg-light {
  background-color: #f8f8f8 !important;
}

.bg-light-2 {
  background-color: #f9f9f9 !important;
}

.bg-lighter {
  background-color: #fafafa !important;
}

.bg-gray {
  background-color: #f4f5f8 !important;
}

.bg-primary {
  background-color: $primary-color !important;
}

.bg-secondary {
  background-color: $secondary-color !important;
}

.bg-image {
  background-color: #789;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.icon-check-circle-o {
  color: $primary-color;
  font-size: 2rem;
  margin: 0 1.5rem;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.bg-overlay {
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0.3;
    background-color: #333;
  }

  .container,
  .container-fluid {
    position: relative;
    z-index: 2;
  }
}

.input-group-append {
  margin-left: 0;
}

/* Animation for lazyload */
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Animation for Skeleton Loading */
@keyframes skeletonloading {
  to {
    background-position: 400% 0;
  }
}

.owl-dots {
  line-height: 0;
  text-align: center;
  -webkit-tap-highlight-color: transparent;

  .owl-dot {
    display: inline-block;
    zoom: 1;
    *display: inline;

    span {
      width: $dot-simple-width;
      height: $dot-simple-height;
      margin: $dot-simple-margin;
      border: 0.1rem solid $dot-simple-border;
      background: $dot-simple-background;
      display: block;
      -webkit-backface-visibility: visible;
      transition: all 0.3s ease;
      border-radius: $dot-simple-rounded;
    }

    &:hover {
      span {
        border-color: $dot-simple-border-active;
        background: $dot-simple-background-active;
      }
    }

    &.active {
      span {
        width: $dot-simple-active-width;
        border-color: $dot-simple-border-active;
        background: $dot-simple-background-active;
      }
    }
  }
}
