div.product-image {
  padding: 0;
}

div.sub-actions {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    padding: 0;
    button {
      max-width: 100%;
      min-width: 100%;
    }
  }
}
div.shopping-cart-information {
  width: 100%;
  display: block;
  border: 1px solid #e9e9e9;
  // margin-top: 6rem ;

  div.information-title {
    padding: 1rem 0;
    text-align: center;
    border-bottom: 1px solid #e9e9e9;
  }
  form.information-form {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 2rem 1rem;
  }
}
