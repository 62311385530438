@import "../config/variables";

.unavailable-product-label {
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
  align-items: center;
  font-size: 1.4rem;
  font-weight: normal;
  color: $primary-color;

  i {
    font-size: 2rem;
  }
}

.cart-product-image {
  width: 10rem;
  height: 10rem;
  > .wrapper-image {
    width: 100%;
    width: 10rem;
    height: 10rem;
    margin-right: auto;
    margin-left: auto;
    display: block !important;
    img {
      width: 100%;
    }
  }
}

.package-card {
  height: fit-content !important;
  padding: 2rem 1rem;

  .product-image {
    width: 10rem;
    height: 10rem;
    object-fit: contain;

    > .wrapper-image {
      width: 100%;

      img {
        width: 100%;
      }
    }
  }

  .product-image-container {
    flex: 1;
    max-width: 24rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

div.product-card {
  display: block;
  transition: all 0.3s ease;
  position: relative;
  height: 40rem;

  > a {
    display: block;
    position: relative;
    height: auto;
  }

  div.product-actions {
    position: absolute;
    right: 1rem;
    top: 1rem;
    height: auto;
    z-index: 2;
    transition: all 0.3s ease;

    button.add-to-wish-list {
      width: 3.5rem;
      height: 3.5rem;
      min-width: unset;
      font-size: 2.2rem;
    }
  }

  button.add-to-card {
    position: absolute;
    top: calc(26rem - 3.4rem);
    left: 0;
    width: 100%;
    height: 3.4rem;
    font-size: 1.4rem;
    background-color: #fff;
    color: $primary-color;
    z-index: 2;
    transition: all 0.3s ease;
    border-bottom: 1px solid #eee;

    &:hover {
      background-color: $primary-color;
      color: white;
    }
  }

  @media (hover: hover) {
    button.add-to-card,
    div.product-actions {
      opacity: 0;
      visibility: hidden;
    }
  }

  &.card-in-slide {
    margin: 1rem 0 3rem;

    div.product-unavilable {
      left: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    div.product-image {
      width: 100%;
    }
  }

  &.type-list {
    height: auto;
    min-height: auto;
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    padding: 1rem 3rem;
    column-gap: 2rem;

    > div {
      column-gap: 2rem;
    }

    button.add-to-card {
      top: auto;
      bottom: 2rem;
      right: 2rem;
      left: auto;
      width: 20rem;
      opacity: 1;
      visibility: visible;
      box-shadow: none;
      border: 1px solid $primary-color;
    }

    div.product-unavilable {
      left: auto;
      right: 1rem;
      top: 1rem;
    }

    span.product-image {
      height: unset;
      width: 26rem;
    }

    div.product-body {
      height: unset;
      width: calc(100% - 2rem - 26rem);
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      padding: 0;

      div.row-container {
        display: flex;
        background-color: red;
        justify-content: center;
        align-items: center;

        p.product-title {
          font-size: 18px;
        }
      }
    }
  }

  .row-container {
    display: flex;
    margin-top: 1rem;

    // justify-content: center;
    // align-items: center;
    p.product-title {
      font-size: 18px;
    }
  }

  div.product-unavilable {
    position: absolute;
    top: 0.5px;
    left: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 15px;
    color: $primary-color;
    z-index: 2;
    transition: all 0.4s ease;

    span.icon {
      display: flex;
      justify-content: center;
      margin-right: 0.5rem;
      align-items: center;
      border: 1px solid $primary-color;
      border-radius: 50%;
      width: 18px;
      font-size: 1.4rem;
      height: 18px;
    }
  }

  div.product-image {
    position: relative;
    overflow: hidden;
    width: unset;
    z-index: 1;

    span.product-image {
      display: flex !important;
      justify-content: center;
      align-items: center;
      height: 26rem;
      overflow: hidden;
      flex-direction: column;
      position: relative;

      img {
        display: block;
        height: auto;
        width: 100%;
        max-height: unset;
      }
    }
  }

  div.product-body {
    padding: 1rem;
    position: static;

    p.product-title {
      text-align: center;
      font-size: 18px !important;
      color: #555;
      margin-bottom: 0.6rem;
    }

    div.row-info {
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 5px;
      line-height: 2.1rem;

      span.info-title {
        font-size: 12px;
        color: #666;
      }

      span.info-title-primary {
        font-size: 12px;
      }

      &.light-info {
        span {
          font-weight: 100;
          color: #999;
          font-size: 1.5rem;
        }
      }
    }

    p.product-description {
      font-size: 1.5rem;
    }
  }

  &:not(.type-list):hover {
    box-shadow: 0 5px 20px rgba(234, 234, 234, 0.8);

    div.product-actions {
      visibility: visible;
      opacity: 1;
    }

    button.add-to-card {
      opacity: 1;
      visibility: visible;
    }
  }
}

.info-title {
  font-size: 12px;
  color: #666;
}

.info-title-primary {
  font-size: 1.5rem;
}

.product-card-title {
  font-size: 16px;
  font-weight: 600;
  text-align: center;

  &:hover {
    color: $primary-color;
  }
}

.info-value {
  font-size: 16px;
  font-weight: 600;
}
