div.swiper-main {
  width: calc(100% - 8rem);
  margin: 0 auto;
  &.full-width {
    width: 100%;
  }
  div.swiper-button-next,
  div.swiper-button-prev {
    color: $primary-color;
    &::after {
      font-size: 40px;
    }
  }
  div.swiper-pagination {
    span.swiper-pagination-bullet {
      &.swiper-pagination-bullet-active {
        border-radius: 9px;
        width: 17px;
        background-color: $primary-color;
      }
    }
  }
}

div.swiper-main2 {
  margin: 0 auto;
  width: 100%;
  padding: 2rem 4rem;
  &.full-width {
    width: 100%;
  }
  div.swiper-button-next,
  div.swiper-button-prev {
    color: $primary-color;
    &::after {
      font-size: 40px;
    }
  }
  div.swiper-pagination {
    span.swiper-pagination-bullet {
      &.swiper-pagination-bullet-active {
        border-radius: 9px;
        width: 17px;
        background-color: $primary-color;
      }
    }
  }
}
